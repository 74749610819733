import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Select, Typography } from "@mui/material"
import { LocationUpdateStyles } from "./LocationUpdateStyles"
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import {ReactComponent as PencilEdit} from '../../../../assets/pencil_edit.svg'
import {ReactComponent as FileTextEdit} from '../../../../assets/file_text_edit.svg'
import React from "react"

type LocationUpdateProp = {
    openLocationDialog: boolean
    setOpenLocationDialog: (open: boolean) => void

}
const LocationUpdate = ({openLocationDialog, setOpenLocationDialog}: LocationUpdateProp) => {
    return <Dialog
                fullWidth={true}
                open={openLocationDialog} onClose={()=>{
                setOpenLocationDialog(false)
    }}>
        <DialogTitle>
            <Grid>
                <Typography sx={LocationUpdateStyles.headerText}>Select Location</Typography>
                <Typography sx={LocationUpdateStyles.paragraph1}>
                    The invoice you selected has a customer with multiple locations. Please select the location you want to deliver to or add a new one.
                </Typography>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid>
                <Box>
                    <Typography sx={LocationUpdateStyles.invoiceText}>Invoice #</Typography>
                    <Typography sx={LocationUpdateStyles.companyNameText}>Firestone North AVE</Typography>
                    <Typography sx={LocationUpdateStyles.smallText}>PO: #1826 - station 198</Typography>
                </Box>
                <Box>
                    <Box display={"flex"} flexDirection={"row"} gap={"6px"}>
                        <Box display={"flex"} flexDirection={"row"} gap={"6px"} alignItems={"center"}>
                            <PinIcon/>
                            <Typography sx={LocationUpdateStyles.smallBold}>
                                Delivery Location
                            </Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <PencilEdit/>
                             
                            <Button sx={LocationUpdateStyles.addLocation} onClick={()=>{
               
                            }}> Add Location</Button>
                        </Box>
                    </Box>
                    <Box>
                         <Select label="Drop off Name" value="Test Address" style={{width:'376px',height:'35px'}}></Select>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} gap={"4px"}>
                    <Typography sx={LocationUpdateStyles.address}>123,Address Ln</Typography>
                    <Typography sx={LocationUpdateStyles.address}>City,St 12345</Typography>
                    </Box>
                    
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={"6px"}>
                        {/* Neeed to confirm Delivery notes is a editable */}
                        <FileTextEdit/>
                        <Typography sx={LocationUpdateStyles.deliveryNotes}>Delivery Note</Typography>
                    </Box>
                    <Typography>Turn right at the stop sign to get to the back entrance</Typography>
                </Box>
            </Grid>
        </DialogContent>
        <DialogActions>
        <Button variant='contained' fullWidth>Confirm</Button>
        </DialogActions>
    </Dialog>
}

export default LocationUpdate;