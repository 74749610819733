import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardHeader,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  styled
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Avatar from '@mui/material/Avatar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import userCardStyles from './userCardStyles'
import Colors from '../../../../assets/Colors'
import { AppUser } from '../../../../utilities/services/UserManagementService'
import React, { useEffect, useState, useContext } from 'react'
import { DeliveryRoute, Invoice, Stop } from '../../../../utilities/types/DeliveryRouteTypes'
import driver_doordash from '../../../../assets/driver.png'
import { convertEpochToTimezoneFormat, getStringToTime } from '../../../../utilities/helpers/DateTimeFormatters'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'

type UserCardProps = {
  user: AppUser
  routeData: DeliveryRoute[]
  isLoadingDrivers: boolean
}


export const getStopStatus = (routeStatus: string, stopDelivery: Stop, currentLanguage: string) => {
  if (routeStatus === 'in-progress') {
    if (stopDelivery.deliveryTimestamp) {
      return (language as any)[currentLanguage].delivered
    }
    return (language as any)[currentLanguage].statusInprogress
  }
  if (routeStatus === 'pending') {
    return (language as any)[currentLanguage].statusPending
  }
  if (routeStatus === 'complete') {
    return (language as any)[currentLanguage].complete
  }
}

const UserCard = ({ user, routeData, isLoadingDrivers }: UserCardProps) => {
  const [userRoutes, setUserRoutes] = useState<any>(null)
  const { currentLanguage } = useContext(LanguageContext)
  const { storeAddress } = useContext(StoreContext)

  useEffect(() => {
    if (routeData && routeData?.length > 0) {
      const filteredRoutes = routeData?.filter(
        (route: DeliveryRoute) =>
          route?.routeStatus !== 'cancelled' &&
          Date.now() - route.routeCreationTimestamp <= 3600000 * 24 &&
          route.stops?.length > 0
      )
      setUserRoutes(sortAndOrganizeDeliveryData(filteredRoutes))
    } else {
      setUserRoutes(sortAndOrganizeDeliveryData(routeData))
    }
  }, [routeData])

  const sortAndOrganizeDeliveryData = (data:any)=> {
    // Segregate data by routeStatus
    const segregatedData = data.reduce((acc:any, item:any) => {
        acc[item.routeStatus] = acc[item.routeStatus] || [];
        acc[item.routeStatus].push(item);
        return acc;
    }, {});
    // Sort each category by routeId
    for (const status in segregatedData) {
      segregatedData[status].sort((a: DeliveryRoute, b: DeliveryRoute) =>
          a.routeCreationTimestamp - b.routeCreationTimestamp)
        segregatedData[status].sort((a:any, b:any) => b.routeId - a.routeId);
    }
    // Define the order of statuses
    const statusOrder = ['in-progress', 'pending', 'complete'];
    // Merge and sort by the defined order of statuses
    return statusOrder.reduce((acc, status) => {
        if (segregatedData[status]) {
            acc = acc.concat(segregatedData[status]);
        }
        return acc;
    }, []);
}

const getETA = (route:DeliveryRoute, eta:number) => {
  const deliveredStops = route.stops.filter((stop:any)=> stop.delivered);
  
  if(!deliveredStops.length) return convertEpochToTimezoneFormat(route?.routeCreationTimestamp, eta);
  const lastDeliveredTimeStamp = Math.max(...deliveredStops.map((dStop:Stop) => dStop.deliveryTimestamp ));
  return convertEpochToTimezoneFormat(lastDeliveredTimeStamp, eta);
}


  return (
    <Grid>
      {isLoadingDrivers ? (
        <Box>
          <Card sx={userCardStyles.userCardContainer}>
            <CircularProgress sx={{ padding: 6 }} size={20} />
          </Card>
        </Box>
      ) : (
        <Card
          style={{
            ...userCardStyles.userCardContainer,
            height: !userRoutes || userRoutes < 1 ? '154px' : 'fit-content'
          }}>
          {user.username !== "DOORDASH" ? <CardHeader
            style={userCardStyles.userCardHeader}
            avatar={
              user.givenName && user.familyName ? (
                <Avatar>
                  {user.givenName[0]}
                  {user.familyName[0]}
                </Avatar>
              ) : (
                <Avatar>NAPA</Avatar>
              )
            }
            title={`${user.givenName} ${user.familyName}`}
            titleTypographyProps={userCardStyles.userCardHeaderTypography}></CardHeader> :
            <Grid item xs={12} sx={userCardStyles.userCardHeaderTypography} marginLeft={2} marginTop={1}>
              <img
                src={driver_doordash}
                alt={'doordash'}
                style={{
                  width: '288px',
                  height: '56px'
                }}
              />
            </Grid>}
          {userRoutes && userRoutes?.length > 0 ? (
            userRoutes
              /* eslint-disable */
              .map((route: DeliveryRoute, idx: number) => {
                if (route.routeStatus === 'complete' && route.deliveryDriver !== 'DOORDASH')
                  return (
                    <Grid>
                      <CardContent key={idx}>
                        <Accordion style={userCardStyles.accordionComplete} defaultExpanded={false}>
                          <AccordionSummary aria-controls="route-content" id="route-id">
                            <Box>
                              <Typography
                                variant="subtitle2"
                                mb='5px'
                                style={userCardStyles.userCardRouteIdTypography}>
                                {' '}
                                {(language as any)[currentLanguage].routeNumber}{route.routeId}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                fontWeight={400}
                                style={userCardStyles.completedTypography}>
                                {(language as any)[currentLanguage].completed}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                        </Accordion>
                      </CardContent>
                    </Grid>
                  )
                if (route.stops?.length > 0) {
                  let delayReason = route.stops?.filter(stop => stop.delayReason && stop.delayReason !== null).flatMap(stop => stop.delayReason)
                  if (route.stops?.length === 1 && route.stops[0].stopType === 'back_to_store')
                    return
                  return (
                    <Grid>
                      <CardContent key={idx} sx={userCardStyles.lastCardContent}>
                        <Typography
                          variant="subtitle2"
                          mb='14px'
                          style={userCardStyles.userCardRouteIdTypography}>
                          {' '}
                          {(language as any)[currentLanguage].routeNumber}{route.routeId}
                        </Typography>
                        {delayReason?.length > 0 && <Grid style={{display: 'flex'}}>
                          <Typography
                            fontWeight={700}
                            style={userCardStyles.delayTitle}>
                            Delayed:
                          </Typography>
                          <Typography
                            fontWeight={400}
                            style={userCardStyles.delayTitle}>
                            &nbsp;{delayReason.length > 1 ? `${delayReason.length} Reported` : delayReason.join(', ')}
                          </Typography>
                        </Grid>}
                        {route.deliveryDriver === "DOORDASH" && <>
                          <Grid container>
                            <Grid
                              xs={7}
                              sx={userCardStyles.thirdPartyGrid}
                            >
                              {(language as any)[currentLanguage].createdAt}
                            </Grid>
                            {route?.routeCreationTimestamp && <Grid
                              xs={5}
                              sx={userCardStyles.thirdPartyGrid}
                              textTransform={'uppercase'}
                            >
                              {convertEpochToTimezoneFormat(route.routeCreationTimestamp)}
                            </Grid>}
                          </Grid>
                          <Grid container>
                            <Grid
                              xs={7}
                              sx={userCardStyles.thirdPartyGrid}
                            >
                              {(language as any)[currentLanguage].estimatedTime}
                            </Grid>
                            <Grid
                              xs={5}
                              sx={userCardStyles.thirdPartyGrid}
                              textTransform={'uppercase'}
                            >
                              {getStringToTime(route?.externalDelivery?.pickupTimeEstimated || "")}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid
                              xs={7}
                              sx={userCardStyles.thirdPartyGrid}
                            >
                              ETA
                            </Grid>
                            <Grid
                              xs={5}
                              sx={userCardStyles.thirdPartyGrid}
                            >
                              {getStringToTime(route?.externalDelivery?.dropoffTimeEstimated || "")}
                            </Grid>
                          </Grid>
                        </>}
                        {route.stops.map((stop: Stop, idx: number) => {
                          const inprogressStops = route.stops.filter((inpStop:any) => !inpStop.delivered)
                          let eta = inprogressStops?.reduce((acc, obj) => acc + (obj['expectedDriveDurationMinutes'] ?? 0), 0);
                          return (
                            <>
                              {stop.stopType !== 'back_to_store' ? <Accordion
                                sx={userCardStyles.accordianSummary}
                                defaultExpanded={route.routeStatus !== 'complete'}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="route-content"
                                  id="route-id">
                                  <Grid>
                                    <Box style={userCardStyles.stopDeliveryAddress}>
                                      <CheckCircleOutlineIcon
                                        style={{
                                          color: stop.deliveryTimestamp || (route?.externalDelivery?.dropoffTimeEstimated && route.routeStatus === "complete")
                                            ? Colors.napaGreenDark
                                            : Colors.napaGrey,
                                          marginRight: '25px',
                                          marginTop: '10px'
                                        }}
                                      />
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight={400}
                                        style={userCardStyles.userCardDeliveryAddressTypography}>
                                        {stop?.invoices && stop?.invoices[0]?.deliveryAddress?.name}
                                      </Typography>
                                    </Box>
                                    <Box style={userCardStyles.invoiceRouteStatusBox}>
                                      <Typography variant="subtitle2">
                                        {(language as any)[currentLanguage].totalInvoices} {stop.invoices?.length}
                                      </Typography>
                                      {stop.deliveryTimestamp || (route?.externalDelivery?.dropoffTimeEstimated && route.routeStatus === "complete") ? (
                                        <Typography
                                          variant={'subtitle2'}
                                          style={{
                                            color: Colors.napaGreenDark,
                                            marginInline: '10px '
                                          }}>
                                          {(language as any)[currentLanguage].dlvr}{' '}
                                          {route.deliveryDriver === 'DOORDASH' ? getStringToTime(route?.externalDelivery?.dropoffTimeEstimated || "") : convertEpochToTimezoneFormat(stop.deliveryTimestamp)}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="subtitle2"
                                          style={{
                                            color:
                                              route.routeStatus === 'in-progress'
                                                ? Colors.napaGreenDark
                                                : Colors.napaGrey,
                                            marginInline: '20px'
                                          }}>
                                          {getStopStatus(route.routeStatus, stop, currentLanguage)}
                                        </Typography>
                                      )}
                                    </Box>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails sx={userCardStyles.accordianExpanded} key={idx}>
                                  <hr style={userCardStyles.hr} />
                                  {stop.invoices?.map((invoice: Invoice) => {
                                    return (
                                      <>
                                        <Typography
                                          variant="subtitle2"
                                          style={
                                            stop.signatureCaptureImage
                                              ? userCardStyles.userCardComplete
                                              : userCardStyles.userCardPending
                                          }>
                                          {' '}
                                          {(language as any)[currentLanguage].invoice}{invoice.invoiceNumber}
                                        </Typography>
                                        {invoice.parts.map((part) => {
                                          return (
                                            <Grid container style={userCardStyles.partDetails}>
                                              <Grid xs={2}> ({part.requestedQuantity})</Grid>
                                              <Grid xs={2}> {part.lineAbbreviation}</Grid>
                                              <Grid xs={8}> {part.partNumber}</Grid>
                                            </Grid>
                                          )
                                        })}
                                      </>
                                    )
                                  })}
                                  {/*TODO: Need to add this to the stop response and to the delivery details table*/}
                                </AccordionDetails>
                              </Accordion> : route.deliveryDriver === 'DOORDASH'? null :
                                <DialogContent sx={userCardStyles.returnStoreContainer}>
                                  <Box sx={userCardStyles.returnStore}>
                                    <NameContainer sx={userCardStyles.invoiceDeliveryAddress}>
                                      {(language as any)[currentLanguage].returnToStore}
                                    </NameContainer>
                                    {route.routeCreationTimestamp &&
                                      <DialogContentText sx={userCardStyles.invoiceAddressLineType}>
                                        ETA {getETA(route, eta)}
                                      </DialogContentText>
                                    }
                                    <DialogContentText sx={[userCardStyles.invoiceAddressLineType, userCardStyles.addressMargin]}>
                                      {storeAddress?.addressLine1}, {storeAddress?.addressLine2 ?? ''}
                                    </DialogContentText>
                                    <DialogContentText sx={userCardStyles.invoiceAddressLineType}>
                                      {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zipCode}
                                    </DialogContentText>
                                  </Box>
                                </DialogContent>
                              }
                            </>
                          )
                        })}
                      </CardContent>
                    </Grid>
                  )
                }
              })
          ) : routeData === null ? (
            <Box>
              <Typography>{(language as any)[currentLanguage].errorRouteData}</Typography>
            </Box>
          ) : (
            <Box style={userCardStyles.noActiveRoutesBox}>
              <Typography>{(language as any)[currentLanguage].noRoutes}</Typography>
            </Box>
          )}
        </Card>
      )}
    </Grid>
  )
}

const NameContainer = styled(DialogContentText)`
  font-size: 14px;
  color: blue;
`

export default UserCard
