import Colors from '../../../../assets/Colors'

export const invoiceListStyles = {
  dataGridStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  storeName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  autoDispatch: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize:'14px',
    textOverflow: 'ellipsis'
  },
  gridPaper: {
    width: '100%',
    borderRadius: '5px',
    boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)'
  },
  noInvoicesCard: {
    border: 'dashed',
    borderWidth: '1px',
    borderColor: '#C1C1C1',
    backgroundColor: '#F4F4F4',
    width: {
      xs: '100%'
    },
    maxHeight: '60px',
    marginTop: '20px',
    marginBottom: '50px'
  },
  noInvoicesCardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  allInvoices: {
    color: '#001489',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '22px',
    letterSpacing: '0.48px',
    paddingBottom: '15px'
  },
  allInvoicesContainer: {
    paddingTop: '20px',
    paddingX: '16px'
  },
  allInvoicesGrid: {
    width: '821px',
    minHeight: 'auto'
  },
  noInvoices: {
    color: '#707070',
    fontSize: '16px',
    lineHeight: '19.5px',
    textAlign: 'center',
    letterSpacing: '0.48px'
  },
  searchToolBarText: {
    paddingLeft: '5px',
    alignSelf: 'flex-start',
    display: 'flex',
    border: 'none',
    justifyContent: 'center'
  },
  '& .MuiDataGrid-columnHeaders': {
    fontSize: '12px',
    fontFamily: '400',
    lineHeight: '15px',
    letterSpacing: '0.42px',
    color: '#707070'
  },
  '& .MuiDataGrid-cell': {
    fontSize: '14px',
    color: '#33333',
    lineHeight: '22px',
    letterSpacing: '0.48px'
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
    color: '#001489',
    fontWeight: 'bolder'
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent:focus': {
    fontWeight: 'bolder'
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none'
  },
  '& .MuiDataGrid-sortIcon': {
    color: '#001489',
    outline: 'none'
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#E5E7F3'
  },
  gridSize: {
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      fontSize: '12px',
      fontFamily: '400',
      lineHeight: '15px',
      letterSpacing: '0.42px',
      color: '#707070'
    },
    '& .MuiDataGrid-cell': {
      fontSize: '14px',
      color: '#33333',
      lineHeight: '22px',
      letterSpacing: '0.48px'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
      color: '#001489',
      fontWeight: 'bolder'
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent:focus': {
      fontWeight: 'bolder'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row': {
        '&:nth-of-type(2n-1)': { backgroundColor: '#F4F4F4' },
        '&:nth-of-type(2n-1):hover': { backgroundColor: '#E5E7F3' },
        '&:nth-of-type(2n):hover': { backgroundColor: '#E5E7F3' }
      }
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#001489',
      outline: 'none'
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#E5E7F3'
    }
  },
  searchIcon: {
    color: '#001489',
    paddingX: '10px'
  },
  searchBarBox: {
    display: 'flex',
    marginBottom: 15
  },
  searchBar: {
    width: '100%',
    marginTop: 3
  },
  searchResultsBox: {
    display: 'flex',
    justifyContent: 'initial',
    marginBlock: 10
  },
  searchResultsFont: {
    fontFamily: 'NAPA SANS',
    fontSize: '14px',
    color: Colors.napaGrey
  },
  resetButtonStyle:{
    height: 'fit-content',
    marginTop: 20,
    color: Colors.napaBlueLink
  },
  datePickerStyle:{
    paddingLeft: 10, 
    paddingRight: 20, 
    paddingTop: 15
  },
  viewDetailsButton: {
    textTransform: 'none',
    color: Colors.napaBlueLink
  },
  dialogTitle: {
    color: Colors.napaBlue,
    fontSize: '28px',
    fontWeight:700,
    paddingBottom:1
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 16,
    color: 'black',
  },
  closeIconImg: {
    fontSize:'36px'
  },
  dialogActions: {
    flexDirection: 'column',
    alignItems: 'stretch', '& > :not(:first-of-type)': { marginLeft: 0 }
  },
  cancelButton: {
    marginBottom: '8px',
    width: '100%',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '16px',
    height:'44px',
    backgroundColor: 'white'  
  },
  stopAutoDispatchButton: {
    width: '100%',
    fontWeight: '700',
    fontSize: '16px',
    height: '44px',
    textTransform: 'none'
  },
  dialogSize: {
    '.MuiDialog-paper': { maxWidth: '400px' } 
  },
  dialogContentText: {
    marginBottom:'13px'
  },
  dispatchButtonContainer: {
    paddingBottom: 2,
    display: 'flex',
    justifyContent: 'end'
  },
  startAutoDispatchButton: {
    color: Colors.black,
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:disabled': { color: Colors.napaGreyDisabled2 }
  },
  stopAutoDispatch: {
    color: Colors.black,
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:disabled': { color: Colors.napaGreyDisabled2 }
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'start'
  },
  autoDispatchText: {
    color: Colors.napaBlue,
    display: 'flex',
  },
  autoDispatchColumnText: {
    marginLeft: 1.5,
    fontWeight: 700,
    fontSize: 14
  },
  placeHold: {
    textTransform: 'capitalize',
    color: Colors.napaGrey,
    padding: '0 8px 0 0'
  },
  markDelivered: {
    textTransform: 'capitalize',
    color: Colors.napaGrey,
    padding: '0 8px 0 8px'
  },
  removeButton: {
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: Colors.napaRed3,
    borderRadius: 0,
    boxShadow: 'none',
    '&:disabled': { color: Colors.napaGreyDisabled2 }
   
  },
  cellStyleParagaraph2 : {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0.48px',
    textAlign: 'left'
  }
}
